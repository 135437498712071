/** @jsx jsx */
import { jsx, Styled, Flex, Box } from 'theme-ui';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../../state/notifications';
import * as yup from 'yup';
import FormField from '../../components/FormField';
import { Field, Form } from 'formik';
import { Container, RadioField, RadioFieldGroup } from '../../components';
import { withFormik } from 'formik';
import RecaptchaButton from '../../components/RecaptchaButton';
import * as api from '../../utils/api';
import { locNavigate } from '../../state/session';
import { handleFormValidationError } from '../../utils/formError';
import Spinner from '../../components/Spinner';
import * as queryString from 'query-string';
import testLogger from '../../components/test-logger';
import { deviceDetect } from 'react-device-detect';
import * as analytics from '../../utils/analytics';
import Link from '../../components/Link';

const ticketOrderSchema = translate => {
  return yup.object().shape({
    type: yup.string().required(translate('ticketOrderForm.requiredField')),
    age: yup.string().required(translate('ticketOrderForm.requiredField')),
    product: yup.string().required(translate('ticketOrderForm.requiredField')),
    currentCardNumber: yup.string().when('type', {
      is: translate('ticketOrderForm.existingCard'),
      then: yup
        .string()
        .required(translate('ticketOrderForm.requiredField'))
        .length(19, translate('ticketOrderForm.invalidCardNumberLength')),
      otherwise: yup.string(),
    }),
    distance: yup.number().required(translate('ticketOrderForm.requiredField')),
    name: yup.string().required(translate('ticketOrderForm.requiredField')),
    email: yup
      .string()
      .required(translate('ticketOrderForm.requiredField'))
      .email(translate('ticketOrderForm.invalidEmail')),
    address: yup.string().required(translate('ticketOrderForm.requiredField')),
    postalCode: yup.string().required(translate('ticketOrderForm.requiredField')),
    city: yup.string().required(translate('ticketOrderForm.requiredField')),
    phoneNumber: yup.string().required(translate('ticketOrderForm.requiredField')),
    receiverName: yup.string(),
    receiverEmail: yup.string(),
    receiverAddress: yup.string(),
    receiverPostalCode: yup.string(),
    receiverCity: yup.string(),
    receiverPhoneNumber: yup.string(),
    recaptcha: yup.string().required(),
  });
};

const TicketOrderForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  nocaptcha,
  values,
  touched,
}) => {
  const products = [
    {
      label: translate('ticketOrderForm.products.1'),
    },
    {
      label: translate('ticketOrderForm.products.2'),
    },
    {
      label: translate('ticketOrderForm.products.3'),
    },
    {
      label: translate('ticketOrderForm.products.4'),
    },
    {
      label: translate('ticketOrderForm.products.5'),
    },
  ];
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <RadioFieldGroup id="type" error={errors.type} label={''}>
        <Field
          component={RadioField}
          name="type"
          id={translate('ticketOrderForm.newCard')}
          label={translate('ticketOrderForm.newCard')}
        />
        <Field
          component={RadioField}
          name="type"
          id={translate('ticketOrderForm.existingCard')}
          label={translate('ticketOrderForm.existingCard')}
        />
        {values?.type === translate('ticketOrderForm.existingCard') && (
          <Box sx={{ mt: 3 }}>
            <FormField name="currentCardNumber" label={`${translate('ticketOrderForm.existingCardNumber')} *`} />
          </Box>
        )}
      </RadioFieldGroup>
      <RadioFieldGroup id="age" error={errors.age} label={''}>
        <Flex sx={{ gap: 4 }}>
          <Field
            component={RadioField}
            name="age"
            id={translate('ticketOrderForm.adult')}
            label={translate('ticketOrderForm.adult')}
          />
          <Field
            component={RadioField}
            name="age"
            id={translate('ticketOrderForm.child')}
            label={translate('ticketOrderForm.child')}
          />
        </Flex>
      </RadioFieldGroup>
      <RadioFieldGroup
        id="product"
        error={errors.product && touched.product}
        label={translate('ticketOrderForm.chooseProduct')}
      >
        {products.map(p => (
          <Field component={RadioField} name="product" id={p.label} label={p.label} />
        ))}
      </RadioFieldGroup>
      <Box sx={{ width: 600, maxWidth: '100%' }}>
        <Flex sx={{ flexDirection: 'row', alignItems: 'center', gap: 3 }}>
          <FormField name="distance" type="number" min={1} label={translate('ticketOrderForm.distance')} />
          <span>km</span>
        </Flex>
      </Box>

      <p>{translate('ticketOrderForm.sentToEmail')}</p>
      <p>{translate('ticketOrderForm.sendText')}</p>
      <Styled.h2>{translate('ticketOrderForm.contact.buyerTitle')}</Styled.h2>
      <FormField name="name" label={`${translate('ticketOrderForm.contact.name')} *`} />
      <FormField name="email" label={`${translate('ticketOrderForm.contact.email')} *`} />
      <FormField name="address" label={`${translate('ticketOrderForm.contact.address')} *`} />
      <FormField name="postalCode" label={`${translate('ticketOrderForm.contact.postalCode')} *`} />
      <FormField name="city" label={`${translate('ticketOrderForm.contact.city')} *`} />
      <FormField name="phoneNumber" label={`${translate('ticketOrderForm.contact.phone')} *`} />

      <Styled.h2>{translate('ticketOrderForm.contact.receiverTitle')}</Styled.h2>
      <FormField name="receiverName" label={translate('ticketOrderForm.contact.name')} />
      <FormField name="receiverEmail" label={translate('ticketOrderForm.contact.email')} />
      <FormField name="receiverAddress" label={translate('ticketOrderForm.contact.address')} />
      <FormField name="receiverPostalCode" label={translate('ticketOrderForm.contact.postalCode')} />
      <FormField name="receiverCity" label={translate('ticketOrderForm.contact.city')} />
      <FormField name="receiverPhoneNumber" label={translate('ticketOrderForm.contact.phone')} />
      <p
        dangerouslySetInnerHTML={{
          __html: translate('ticketOrderForm.privacy'),
        }}
        sx={{ a: { textDecoration: 'underline' } }}
      ></p>
      <p
        dangerouslySetInnerHTML={{
          __html: translate('ticketOrderForm.acceptTerms'),
        }}
        sx={{ a: { textDecoration: 'underline' } }}
      ></p>
      <p>{translate('ticketOrderForm.info')}</p>

      <RecaptchaButton
        buttonText="ticketOrderForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
        errors={errors}
        disabled={!values?.product}
      />
    </Form>
  );
};

const TicketOrderFormik = withFormik({
  mapPropsToValues: ({ vo, translate }) =>
    Object.assign({
      type: vo.type || translate('ticketOrderForm.newCard'),
      age: vo.age || translate('ticketOrderForm.adult'),
      product: vo.product || translate('ticketOrderForm.products.1'),
      name: vo.name || '',
      currentCardNumber: vo.currentCardNumber || 'F246300020',
      email: vo.email || '',
      address: vo.address || '',
      postalCode: vo.postalCode || '',
      city: vo.city || '',
      phoneNumber: vo.phoneNumber || '',
      receiverName: vo.receiverName || '',
      receiverEmail: vo.receiverEmail || '',
      receiverAddress: vo.receiverAddress || '',
      receiverPostalCode: vo.receiverCostalCode || '',
      receiverCity: vo.receiverCity || '',
      receiverPhoneNumber: vo.receiverPhoneNumber || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return ticketOrderSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'TicketOrderForm',
})(TicketOrderForm);

//

const TicketOrderFormPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendValueTicketOrderForm({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: {
              message: translate('ticketOrderForm.success'),
              noDefault: true,
            },
          })
        );
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        testLogger.log('ticketOrderForm:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('ticketOrderForm.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout title={translate('ticketOrderForm.title')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('ticketOrderForm.title')}</Styled.h1>
        <p>{translate('ticketOrderForm.text1')}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: translate('ticketOrderForm.text2'),
          }}
          sx={{ a: { textDecoration: 'underline' } }}
        ></p>
        <p
          dangerouslySetInnerHTML={{
            __html: translate('ticketOrderForm.text3'),
          }}
          sx={{ a: { textDecoration: 'underline' } }}
        ></p>

        <TicketOrderFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default TicketOrderFormPage;
